import React, {Component} from "react";
import '../stylesheets/Sass/contact.css'
import Header from './Header'

function Contact(){
    return (
        <div>
            <Header></Header>
            <div className="container">
                <div className="contactDiv">
                    <div className="sub"></div>
                    <div className="contact">
                        <p>+220 3626260</p>
                        <a href="mailto:bbabucarr32@gmail.com">bbabucarr32@gmail.com</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact