import React, { Component } from 'react'
import Header from './Header'
import Body from './Body'
import MyWork from './MyWork'
import Contact from './Contact'
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 


export class Homepage extends Component {
  render() {
    return (
      <div>
          {/* < Header /> */}
          <Router>
            <Routes>
              <Route path='/' element={< Body />}/>
              <Route path='/mywork' element={< MyWork />}/>
              <Route path='/contact' element={< Contact />}/>
              <Route path='/aboutme' element={< MyWork />}/>
            </Routes>
          </Router>
      </div>
    )
  }
}

export default Homepage
