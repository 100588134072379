import React, { Component } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import {IonIcon} from '@ionic/react'
import {home, chatbox, logoGithub, briefcase, informationCircle, menu} from 'ionicons/icons'
import '../stylesheets/Sass/Header.css'

export class Header extends Component {
  render() {
    let toggled = false; //Declare var

    const toggleMenu = ()=>{ // Get list elements
      const listElements = document.getElementsByClassName('icon');

      // Overwritng the default value of toggled
      toggled = !toggled;
      let toggle = toggled;
      toggled = toggle;
      // Overwritng ends!

      for(let listElement of listElements){
        if(toggle){
          // Display elements
          listElement.style.display = 'block';
        }
        else{
           // hide elements
          listElement.style.display = 'none';
        }
      }
    }
    const currentURL = window.location.href;
    if(currentURL.slice(-1) != '/'){
      return (
        <div>
          <header>
              <ul>
                  <li className='menu' onClick={toggleMenu}><IonIcon icon={menu} style={{marginRight:'5px'}}></IonIcon></li>
                  <li className='icon'><IonIcon icon={home} style={{marginRight:'5px'}}></IonIcon><Link to="/" style={{textDecoration: "none"}}>Home</Link></li>
                  <li className='icon'><IonIcon icon={briefcase} style={{marginRight:'5px'}}></IonIcon><Link to="/mywork" style={{textDecoration: "none"}}>My work</Link></li>
                  <li className='icon'><IonIcon icon={chatbox} style={{marginRight:'5px'}}></IonIcon><Link to="/contact">Contact</Link></li>
                  <li className='icon'><IonIcon icon={informationCircle} style={{marginRight:'5px'}}></IonIcon><Link to="">About me</Link></li>
                  <li className='icon'><IonIcon icon={logoGithub} style={{marginRight:'5px'}}></IonIcon><Link to="https://www.github.com/babucarr32" target="_blank">Github</Link></li>
              </ul>
          </header>
        </div>
      )
    }
    return (
      <div>
        <header>
            <ul>
                <li className='menu' onClick={toggleMenu}><IonIcon icon={menu} style={{marginRight:'5px'}}></IonIcon></li>
                <li className='icon'><IonIcon icon={briefcase} style={{marginRight:'5px'}}></IonIcon><Link to="/mywork" style={{textDecoration: "none"}}>My work</Link></li>
                <li className='icon'><IonIcon icon={chatbox} style={{marginRight:'5px'}}></IonIcon><Link to="/contact">Contact</Link></li>
                <li className='icon'><IonIcon icon={informationCircle} style={{marginRight:'5px'}}></IonIcon><Link to="">About me</Link></li>
                <li className='icon'><IonIcon icon={logoGithub} style={{marginRight:'5px'}}></IonIcon><Link to="https://www.github.com/babucarr32" target="_blank">Github</Link></li>
            </ul>
        </header>
      </div>
    )
    
  }
}

export default Header
