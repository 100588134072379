import React, { Component, useRef } from 'react'
import Header from './Header'
import emailjs from '@emailjs/browser';
import '../stylesheets/Sass/master.css'
import Hexes from './Hexes';
import Intro from './Intro';
import Info from './Info';
import IntroText from './IntroText';


function Body(){
    let successMessage = '';
    React.useEffect(() => {
        document.title = "Bbadjie";
      }, []);

    // Emailjs
    const form = useRef();

    const sendEmail = (e) => {
        console.log('submitted')
        e.preventDefault();
        
        emailjs.sendForm('service_73a3y0e', 'template_hgmtujp', form.current, '7HXC6ClDr7jSGnPmQ')
        .then((result) => {
            // below is resetting the input field after submit
                const fieldToReset = document.getElementsByClassName('qReset');
                for(let field of fieldToReset){
                    field.value = '';
                }
                setTimeout(()=>{
                    const successMessage = "Message successfully sent, thanks."
                    document.getElementById('successMessage').style.display = 'block';
                    document.getElementById('successMessage').innerText = successMessage;
                    setTimeout(()=>{
                        document.getElementById('successMessage').style.display = 'none';
                    }, 4000)
                })
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
        });
    };
    return (
      <div>
        <Header></Header>
        <div className="topContainer">
            <Hexes />
            <Intro />
        </div>
        <IntroText/>
        <Info successMessage={successMessage} form={form} sendEmail={sendEmail} />
      </div>
    )
}

export default Body
