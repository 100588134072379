import React, { Component } from "react";
import "../stylesheets/Sass/work.css";
import Header from "./Header";

export class MyWork extends Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="headerContainer">
          <ul>
            <li>Python</li>
            <li>Django</li>
            <li>Kivymd</li>
            <li>Data Analysis</li>
            <li>Mongo DB</li>
            <li>SQL</li>
            <li>HTML and CSS</li>
            <li>JavaScript</li>
            <li>React</li>
            <li>nodejs</li>
            <li>ExpressJS</li>
            <li>NextJS</li>
            <li>Python module develpment</li>
            <li>Electronics (Arduino)</li>
            <li>Blender</li>
            <li>Autodesk Fusion360</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MyWork;
