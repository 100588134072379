import React from "react";
import "../stylesheets/Sass/intro.css";
import IntroText from "./IntroText";
const image = require("../images/myself.png");

function AddedText(text) {
  return toString(text);
}

export default function Intro() {
  let text = `a creative, resourceful programmer with a knack
    for finding innovative solutions to tricky problems. 
    I'm equally comfortable working independently or collaborating 
    with a team, and I always bring a positive attitude and a can-do spirit
    to any project I tackle.`;
  let i = 0;
  let newText = "";

  let intervalId = setInterval(() => {
    // console.log(text[i]);
    document.getElementById("subIntro").innerText = newText += text[i];
    i++;
    if (i >= text.length) {
      clearInterval(intervalId);
    }
  }, 50);

  return (
    <div className="intros">
      <IntroText />
      <div className="imgDiv">
        <img src={image} alt="" />
      </div>
    </div>
  );
}
