import React from "react";

export default function IntroText(){
    return (
        <div className="intro toHide">
            <p>Hey there, I'm <br /><span className="name">Baboucarr Badjie</span> -</p>
            <p id="subIntro">
                a creative, resourceful programmer with a knack
                for finding innovative solutions to tricky problems. 
                I'm equally comfortable working independently or collaborating 
                with a team, and I always bring a positive attitude and a can-do spirit
                to any project I tackle.
            </p>
        </div>
    )
}