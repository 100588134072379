import React from "react";
import '../stylesheets/Sass/hex.css'

export default function Hexes(){
    return(
        <div className="hexDiv">
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>


            
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
            <div className="divs">
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
                <div className="hex"></div>
            </div>
        </div>
    )
}