import React from "react";


export default function Info(props){
    return (
        <div className="midContainer">
            <div className="questionDiv">
                <p className="question">
                    Do you have questions?
                </p>
                <p id='successMessage' style={{display:'none'}}>{props.successMessage}</p>
                <form ref={props.form} onSubmit={props.sendEmail}>
                    <input className='qReset' placeholder='Your name' type="text" name="user_name"  required/><br />
                    <input className='qReset' placeholder='Your email' type="email" name="userEmail"  required/><br />
                    <textarea className='qReset message' name="message" placeholder='Your question...' required/><br />
                    <button type="submit" value="Send">Submit</button>
                </form>
            </div>
            <div className="Me">
                <p id="about">About Me</p>
                <p>Full Name: <span>Baboucarr Badjie</span></p>
                <p>Age: <span>22</span></p>
                <p>Job: <span>Programmer</span></p>
                <p>Phone: <span>+220 3626260</span></p>
                <p>Email: <span> <a href="mailto:bbabucarr32@gmail.com">bbabucarr32@gmail.com</a></span></p>
                <p>Github: <span><a href="https://www.github.com/babucarr32">babucarr32</a></span></p>
            </div>
        </div>
    )
}